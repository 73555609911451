<template>
  <div class="content">
    <div class="page">
      <div class="img_demo">
        <img src="" alt="" />
        <div class="right">
          <h2>智慧农业</h2>
          <div class="message">
            通过将物联网、大数据、云计算等技术应用到传统农业中，实现了对农业农田项目全周期的可追溯管理，通过农业远程诊断、远程控制等智能管理，极大节省了人力成本，提高了农田管理效率。
          </div>
        </div>
        <!-- <img src="../../assets/image/fbiImg/right_two.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  name: "",
  data() {
    return {};
  },

  methods: {},
};
</script>
 
<style scoped lang="scss">
.content {
  padding: 0 10%;
  min-height: calc(100vh - 387px);
  .page {
    margin-top: 50px;
  }
  .img_demo {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > img {
      display: inline-block;
      width: 50%;
      background: url("../../../../assets/image/ruralImg/img2.png") no-repeat;
      background-size: 100% 100%;
    }
    h2 {
      font-weight: bold;
      margin: 50px 30px 10px 50px;
    }
    .message {
      color: #6b6b6b;
      font-size: 16px;
      margin: 20px 30px 10px 50px;
    }
    .right {
      width: 50%;

      height: 400px;
      background-color: #fff;
    }
  }
}
</style>